import { styled } from '@mui/material';
import Tokens from '@uipath/apollo-core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useHistory,
} from 'react-router-dom';

import Section from '../../components/Section';
import type { ColumnDefinition } from '../../components/Table/BaseTable/types';
import ServerSideTable from '../../components/Table/ServerSideTable/ServerSideTable';
import URLManager from '../../config/URLManager';
import { MLlogsSeverityMapping } from '../../constants/EntityFieldMapping';
import { AppPermissions } from '../../enums/Authorization';
import { PermissionsContext } from '../../providers/PermissionsProvider';
import { ProjectsContext } from '../../providers/ProjectsProvider';
import { RoutePath } from '../../route/routeMap';
import {
    dataFormatter,
    entityDataMapper,
    returnValueIfNotUUID,
} from '../../utils/CommonUtils';
import { dateFormatter } from '../../utils/DateFormatter';

const MlLogsPageContainer = styled('div')({
    width: '100%',
    fontSize: Tokens.FontFamily.FontMSize,
    fontFamily: Tokens.FontFamily.FontNormal,
    overflowY: 'auto',
});

interface MlLogsPageProps {
    auditsUrl: string;
    breadCrumbPath: string;
    breadCrumbData?: any;
    entityLogsPage: boolean;
}

interface MlLogsListProps {
    auditsUrl: string;
    breadCrumbPath: string;
    breadCrumbData?: any;
}

export const MlLogsPageContent: React.FC<MlLogsPageProps> = ({
    auditsUrl, breadCrumbPath, breadCrumbData, entityLogsPage,
}) => {

    const { t } = useTranslation();

    const { currentProjectId } = useContext(ProjectsContext).state;
    const { state: permissionsState } = useContext(PermissionsContext);
    const permissions = permissionsState.projectData[currentProjectId ?? '']?.permissionSet || [];

    return (permissions.indexOf(AppPermissions.MLLogs_View) > -1) ? (
        <MlLogsPageContainer>
            {entityLogsPage === false ?
                (<Section title={t('ml_logs_title')}>
                    <MlLogsList
                        auditsUrl={auditsUrl}
                        breadCrumbPath={breadCrumbPath}
                        breadCrumbData={breadCrumbData} />
                </Section>)
                :
                <MlLogsList
                    auditsUrl={auditsUrl}
                    breadCrumbPath={breadCrumbPath}
                    breadCrumbData={breadCrumbData} />}
        </MlLogsPageContainer >
    ) : null;
};

const MlLogsList: React.FC<MlLogsListProps> = ({
    auditsUrl, breadCrumbPath, breadCrumbData,
}) => {
    const history = useHistory();
    const {
        t, i18n,
    } = useTranslation();
    const dataMapper: ColumnDefinition[] = [
        {
            header: `${t('label_severity')}`,
            accessorKey: 'severity',
            enableSorting: true,
            cell: ({ cell: { getValue } }): string => dataFormatter(getValue()),
        },
        {
            header: `${t('label_about')}`,
            accessorKey: 'entityTypeName',
            enableSorting: true,
            cell: ({ cell: { getValue } }): string => entityDataMapper(getValue(), MLlogsSeverityMapping),
        },
        {
            header: `${t('label_user')}`,
            accessorKey: 'createdBy',
            cell: ({ cell: { getValue } }): string => returnValueIfNotUUID(getValue()),
        },
        {
            header: `${t('label_description')}`,
            accessorKey: 'message',
        },
        {
            header: `${t('label_time')}`,
            accessorKey: 'createdOn',
            enableSorting: true,
            cell: ({ cell: { getValue } }): string => dateFormatter(getValue(), i18n.language),
        },
    ];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleMLLogClicked = (rowInfo: any): void => {
        const projectName = currentProject?.name;
        history.push(
            {
                pathname: generatePath(RoutePath.MLLOGS_DETAILS, { projectName }),
                state: {
                    data: rowInfo.original,
                    // Add breadcumb for central ML Logs page if not given for entity logs
                    breadCrumbPath: (typeof breadCrumbPath === 'undefined' || !breadCrumbPath) ?
                        generatePath(RoutePath.MLLOGS, { projectName }) : breadCrumbPath,
                    breadCrumbData,
                },
            },
        );
    };

    const { state: projectsState } = useContext(ProjectsContext);
    const currentProject = projectsState.currentProject;

    const updatedAuditUrl = React.useMemo(() => auditsUrl + '&projectId=' + currentProject?.id, [ currentProject ]);

    return currentProject?.id ? (
        <ServerSideTable
            url={updatedAuditUrl}
            totalKey="data.totalCount"
            dataKey="data.dataList"
            mapper={dataMapper}
            onTableCellClicked={handleMLLogClicked}
            searchable
            searchKey="searchText"
            level="mllogs_pagesize"
        />
    ) : null;
};

export const MlLogsPage = connect(() => ({
    // Add default audit logs URL for central ML Log page
    auditsUrl: URLManager.url().apiHelper + '/audits?components=ML_SKILL,ML_PACKAGE,PIPELINE,RUN,ML_SERVICE,DATASET&sortBy=createdOn&sortOrder=DESC',
    entityLogsPage: false,
}))(MlLogsPageContent);
