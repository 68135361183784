import { styled } from '@mui/material';
import Tokens from '@uipath/apollo-core';
import React, {
    useCallback,
    useContext,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useHistory,
    useParams,
} from 'react-router-dom';

import {
    deletePackage,
    getPublicProjectsByTenantId,
} from '../../../api/client/pkgManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import createPkgImg from '../../../assets/create-package.svg';
import extractionPkgImg from '../../../assets/extract-pkg.svg';
import { ActionCard } from '../../../components/ActionCard';
import type { ContextMenuItem } from '../../../components/ContextPopup';
import { CustomDialog } from '../../../components/Dialog';
import Section from '../../../components/Section';
import type { ResponsiveColumnDefinition } from '../../../components/Table/BaseTable/types';
import ServerSideTable from '../../../components/Table/ServerSideTable/ServerSideTable';
import URLManager from '../../../config/URLManager';
import { AppPermissions } from '../../../enums/Authorization';
import { PermissionsContext } from '../../../providers/PermissionsProvider';
import { ProjectsContext } from '../../../providers/ProjectsProvider';
import { RoutePath } from '../../../route/routeMap';
import {
    dataFormatter,
    extractErrorMessage,
} from '../../../utils/CommonUtils';
import { dateFormatter } from '../../../utils/DateFormatter';
import logger from '../../../utils/Logging';

const MlPageContainer = styled('div')({
    width: '100%',
    fontSize: Tokens.FontFamily.FontMSize,
    fontFamily: Tokens.FontFamily.FontNormal,
    overflowY: 'auto',

    '& .space-after': { marginRight: '22px' },

    '& .mlPackageList': { marginBottom: '50px' },
});

interface MLPackagesPageProps {
    isPublic: boolean;
}
export const MLPackagesPageFC: React.FC<MLPackagesPageProps> = ({ isPublic }) => {

    const {
        t, i18n,
    } = useTranslation();
    const dataMapper: ResponsiveColumnDefinition[] = [
        {
            header: `${t('common_name_label')}`,
            accessorKey: 'name',
            enableSorting: true,
        },
        {
            header: `${t('label_description')}`,
            accessorKey: 'description',
            enableSorting: true,
            mdDown: true,
        },
        {
            header: `${t('common_status_label')}`,
            accessorKey: 'status',
            enableSorting: true,
            cell: ({ cell: { getValue } }): string => dataFormatter(t(`ML_PACKAGE_STATUS_${getValue()}`), i18n.language),
        },
        {
            header: `${t('common_modified_date_label')}`,
            accessorKey: 'modifiedOn',
            enableSorting: true,
            cell: ({ cell: { getValue } }): string => dateFormatter(getValue(), i18n.language),
            smDown: true,
        },
        {
            header: `${t('common_modified_by_label')}`,
            accessorKey: 'modifiedBy',
            enableSorting: true,
        },
    ];

    const [ open, setOpen ] = useState(false);
    const [ itemDeletedFlag, setItemDeletedFlag ] = useState(false);
    const { projectName } = useParams<{ projectName: string }>();
    const mlPackageName = useRef('');
    const mlPackageId = useRef('');
    const history = useHistory();
    const feedback = useFeedback();

    const {
        currentProject, currentProjectId,
    } = useContext(ProjectsContext).state;
    const { state: permissionsState } = useContext(PermissionsContext);
    const permissions = permissionsState.projectData[currentProjectId ?? '']?.permissionSet || [];

    const url = React.useMemo(() => URLManager.url().apiPkgManager + '/mlpackages?sortBy=createdOn&sortOrder=DESC&projectId=' + currentProject?.id, [ currentProject ]);

    const menuItems: ContextMenuItem[] = [];
    if ((permissions.indexOf(AppPermissions.MLPackages_Edit) > -1)) {
        menuItems.push({
            text: 'ml_package_upload_new_version',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (_event: any, row: any) => {
                if (row.original.sourcePackageId) {
                    const mlPackageOwnedByProjectId: string = row.original.mlPackageOwnedByProjectId;
                    getPublicProjectsByTenantId(row.original.mlPackageOwnedByTenantId).then((projectDtos) => {
                        if (projectDtos) {
                            projectDtos.forEach(projectDto => {
                                if (projectDto.id?.toLowerCase() === mlPackageOwnedByProjectId.toLowerCase()) {
                                    history.push({
                                        pathname: generatePath(RoutePath.UIPATH_PACKAGE, {
                                            projectName,
                                            uiPathProjectName: projectDto.name,
                                            uiPathPackageId: row.original.sourcePackageId,
                                        }),
                                        state: {
                                            data: {
                                                mlpackageId: row.original.id,
                                                mlpackageName: row.original.name,
                                                mlPackageOwnedByAccountId: row.original.mlPackageOwnedByAccountId,
                                                mlPackageOwnedByTenantId: row.original.mlPackageOwnedByTenantId,
                                                mlPackageOwnedByProjectId: row.original.mlPackageOwnedByProjectId,
                                            },
                                        },
                                    });
                                }
                            });
                        }
                        return true;
                    })
                        .catch((error: Error) => {
                            logger.error({
                                identifier: 'ML Package List',
                                message: 'Error getting public projects',
                                error,
                            });
                        });
                } else {
                    history.push(generatePath(RoutePath.NEW_VERSION_MLPACKAGE, {
                        projectName,
                        mlpackageId: row.original.id,
                        mlpackageName: row.original.name,
                    }));
                }
            },
        });
    }

    if ((permissions.indexOf(AppPermissions.MLPackages_Delete) > -1)) {
        menuItems.push({
            text: 'ml_package_delete',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (event: any, row: any): void => {
                mlPackageName.current = row.original.name;
                mlPackageId.current = row.original.id;
                setOpen(true);
            },
        });
    }

    const closeDialog = (): void => {
        setOpen(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleMLPackageClickedEvent = (row: any): void => {
        history.push({
            pathname: generatePath(RoutePath.MLPACKAGE_VERSION, {
                projectName,
                mlpackageId: row.original.id,
                mlpackageName: row.original.name,
            }),
            state: {
                data: {
                    sourcePackageId: row.original.sourcePackageId,
                    mlPackageOwnedByAccountId: row.original.mlPackageOwnedByAccountId,
                    mlPackageOwnedByTenantId: row.original.mlPackageOwnedByTenantId,
                    mlPackageOwnedByProjectId: row.original.mlPackageOwnedByProjectId,
                },
            },
        });
    };

    const handleMLTemplateClickedEvent = useCallback((): void => {
        history.push(generatePath(RoutePath.UIPATH_PROJECTS, { projectName }));
    }, []);

    const handleMLPackageUploadClicked = useCallback((): void => {
        history.push(generatePath(RoutePath.CREATE_MLPACKAGE, { projectName }));
    }, []);

    const handleMLPackageImportClicked = useCallback((): void => {
        history.push(generatePath(RoutePath.IMPORT_MLPACKAGE, { projectName }));
    }, []);

    const deleteSelectedMLPackage = (): void => {
        deletePackage(mlPackageId.current, currentProject?.id).then(() => {
            closeDialog();
            feedback.enqueueSuccess(t('feedback_delete_success_mlpkg'));
            // toggle the flag to reload table data
            setItemDeletedFlag(prev => !prev);
            return true;
        })
            .catch(error => {
                closeDialog();
                logger.error({
                    identifier: 'ML Package List',
                    message: 'Error deleting selected ML Package',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(
                    error, t('feedback_delete_error_mlpkg'),
                    {
                        10602: {
                            1: 'MLPackage',
                            0: mlPackageId.current,
                        },
                        20102: {},
                        20103: {},
                    },
                ));
            });
    };

    return (
        <MlPageContainer>
            {(permissions.indexOf(AppPermissions.MLPackages_Create) > -1) ?
                (
                    <Section title={t('ml_package_create')}>
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}>
                            <div
                                className="action-card space-after"
                                data-cy="upload-action-card">
                                <ActionCard
                                    title={t('ml_package_upload')}
                                    onClick={handleMLPackageUploadClicked}
                                    data-testid="upload-action-card"
                                    cardContent={t('ml_package_upload_card_content')}
                                    imgUrl={createPkgImg} />
                            </div>
                            {!(isPublic) ? (
                                <div
                                    className="action-card space-after"
                                    data-cy="uipath-packages-action-card">
                                    <ActionCard
                                        title={t('out_of_the_box_packages')}
                                        cardContent={t('out_of_the_box_packages_card_content')}
                                        onClick={handleMLTemplateClickedEvent}
                                        data-testid="uipath-packages-action-card"
                                        imgUrl={extractionPkgImg}
                                    />
                                </div>
                            ) : null}
                            <div
                                className="action-card space-after"
                                data-cy="import-action-card">
                                <ActionCard
                                    title={t('ml_package_import')}
                                    onClick={handleMLPackageImportClicked}
                                    data-testid="import-action-card"
                                    cardContent={t('ml_package_import_card_content')}
                                    imgUrl={createPkgImg} />
                            </div>
                        </div>
                    </Section>
                ) : null}

            <Section
                title={t('ml_packages_title')}
                className="mlPackageList">
                <CustomDialog
                    title={t('ml_package_delete_dialog_title_text', { mlPackageName: mlPackageName.current })}
                    open={open}
                    handleClose={closeDialog}
                    closeIconButton
                    infoText={t('ml_package_delete_dialog_info_text')}
                    warningText={t('ml_package_delete_dialog_warning_text')}
                    primaryButtonText={t('dialog_button_confirm_text')}
                    secondaryButtonText={t('dialog_button_cancel_text')}
                    primarybuttonCallback={deleteSelectedMLPackage}
                    secondarybuttonCallback={closeDialog}
                />
                {currentProject?.id ? (<ServerSideTable
                    url={url}
                    searchable
                    searchKey="name"
                    totalKey="data.totalCount"
                    dataKey="data.dataList"
                    mapper={dataMapper}
                    contextMenuItems={menuItems}
                    onTableCellClicked={handleMLPackageClickedEvent}
                    itemDeletedFlag={itemDeletedFlag}
                    keyColumnIndex={0}
                    level="mlpackages_pagesize"
                />) : null}
            </Section>
        </MlPageContainer>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default connect((state: any) => ({ isPublic: state.config.isPublic }))(MLPackagesPageFC);
