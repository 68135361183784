export const ExceptionMap: any = {
    10001: 'COMMON_INVALID_REQUEST_BODY_PARAMETER',
    10002: 'COMMON_MISSING_REQUEST_PARAMETER',
    10003: 'COMMON_UNSERIALIZABLE_REQUEST_BODY',
    10004: 'COMMON_BAD_CONTENT',
    10005: 'COMMON_VALIDATION_FAILURE',
    10006: 'COMMON_NAME_REQUEST_CONFLICT',
    10007: 'COMMON_INVALID_NAME',
    10008: 'COMMON_FORBIDDEN_RESOURCE_ACCESS',
    10009: 'COMMON_ACCESS_DENIED',
    10010: 'COMMON_INVALID_API_KEY',
    10101: 'COMMON_INTERNAL_SERVER_ERROR',
    10102: 'COMMON_INITIALIZATION_FAILED',
    10103: 'COMMON_UNSUPPORTED_STORAGE_PROVIDER',
    10104: 'COMMON_UNSUPPORTED_REGISTRY_TYPE',
    10105: 'COMMON_BUCKET_CREATION_FAILED',
    10106: 'COMMON_BUCKET_ACCESS_GRANT_FAILED',
    10107: 'COMMON_NOT_IMPLEMENTED',
    10108: 'COMMON_DIRECTORY_DELETION_FAILED',
    10109: 'COMMON_DIRECTORY_LISTING_FAILED',
    10110: 'COMMON_BUCKET_DELETION_FAILED',
    10111: 'COMMON_CORS_ADDITION_FAILED',
    10112: 'COMMON_REMOVE_BUCKET_ACCESS_FAILED',
    10301: 'COMMON_ERROR_PARSING_CONTENT',
    10302: 'COMMON_ERROR_WRITING_CONTENT',
    10401: 'COMMON_UPLOAD_FAILED',
    10402: 'COMMON_DOWNLOAD_FAILED',
    10403: 'COMMON_COPY_FAILED',
    10404: 'COMMON_LIST_FAILED',
    10405: 'COMMON_PING_FAILED',
    10501: 'COMMON_ERROR_CREATING_TAR',
    10502: 'COMMON_ERROR_ADDING_FILE',
    10503: 'COMMON_ERROR_CREATING_INPUT_STREAM',
    10504: 'COMMON_ERROR_COPYING_FILE',
    10505: 'COMMON_ERROR_CLEANING_DIR',
    10506: 'COMMON_ERROR_DELETING_FILE',
    10507: 'COMMON_ERROR_GETTING_FILE',
    10508: 'COMMON_ERROR_GENERATING_SIGNED_URL',
    10509: 'COMMON_UNABLE_TO_GET_FROM_STORAGE',
    10510: 'COMMON_UNABLE_TO_DELETE_FROM_STORAGE',
    10601: 'COMMON_INVALID_ID_ERROR',
    10602: 'COMMON_ENTITY_NOT_FOUND',
    10603: 'COMMON_ERROR_ENTITY_CREATION',
    10605: 'COMMON_BLOB_DOES_NOT_EXIST',
    10901: 'COMMON_DEFAULT_VALUE_MISSING',
    10902: 'COMMON_DEPENDENT_SERVICE_MISSING',
    10903: 'COMMON_ERROR_DELETING_ENTITIES',
    10904: 'COMMON_ERROR_URI_SYNTAX_INVALID',
    10905: 'COMMON_ERROR_IO_EXCEPTION',
    11002: 'COMMON_REPOSITORY_CREATION_FAILED_MESSAGE',
    20001: 'PKGMANAGER_ERROR_CREATING_ML_PACKAGE',
    20002: 'PKGMANAGER_ERROR_FINDING_ML_PACKAGE_BY_ID',
    20003: 'PKGMANAGER_ERROR_SEARCHING_ML_PACKAGE',
    20004: 'PKGMANAGER_ERROR_FINDING_ML_PACKAGE_COUNT',
    20005: 'PKGMANAGER_ML_PACKAGE_NAME_REQUEST_CONFLICT',
    20006: 'PKGMANAGER_INCORRECT_ML_PACKAGE_INPUT_TYPE',
    20007: 'PKGMANAGER_ERROR_VALIDATING_ML_PACKAGE',
    20008: 'PKGMANAGER_ML_PACKAGE_NAME_INVALID',
    20009: 'PKGMANAGER_ZIP_VALIDATION_FAILED_FOR_ML_PACKAGE_MAIN_FILE_METHOD',
    20010: 'PKGMANAGER_ZIP_VALIDATION_FAILED_FOR_ML_PACKAGE_MAIN_FILE_MAIN_CLASS',
    20011: 'PKGMANAGER_ZIP_VALIDATION_FAILED_FOR_ML_PACKAGE_MISSING_FILE',
    20012: 'PKGMANAGER_ZIP_VALIDATION_FAILED_FOR_INVALID_ZIP_STRUCTURE',
    20013: 'PKGMANAGER_ZIP_VALIDATION_FAILED_FOR_ML_PACKAGE_INVALID_REQUIREMENTS_FILE',
    20014: 'PKGMANAGER_ZIP_VALIDATION_FAILED_FOR_ML_PACKAGE_MISSING_TRAINING_FILE',
    20015: 'PKGMANAGER_ZIP_VALIDATION_FAILED_FOR_ML_PACKAGE_INVALID_TRAINING_REQUIREMENTS_FILE',
    20016: 'PKGMANAGER_ML_PACKAGE_INCORRECT_SOURCE_DETAILS',
    20022: 'PKGMANAGER_CANNOT_IMPORT_TRAINABLE_PACKAGES',
    20300: 'PKGMANAGER_ML_PACKAGE_LANGUAGE_INVALID',
    20101: 'PKGMANAGER_ERROR_SEARCHING_ML_PACKAGE_VERSION',
    20102: 'PKGMANAGER_ML_PACKAGE_VERSION_NOT_FOUND',
    20114: 'PKGMANAGER_ML_PACKAGE_ERROR_DELETING_VERSION_FOR_ML_PACKAGE_USED_BY_PIPELINE',
    20103: 'PKGMANAGER_ML_PACKAGE_VERSION_DELETE_REQUEST_NOT_ACCEPTABLE',
    20105: 'PKGMANAGER_ERROR_DELETING_VERSION_FOR_ML_PACKAGE_RESTRICTED_STATUS',
    20106: 'PKGMANAGER_DOCKER_IMAGE_DOESNT_EXIST_FOR_ML_PACKAGE_VERSION',
    20107: 'PKGMANAGER_ERROR_SEARCHING_TENANT',
    20108: 'PKGMANAGER_TENANT_ALREADY_EXISTS',
    20110: 'PKGMANAGER_ERROR_SCANNING_ML_PACKAGE',
    20111: 'PKGMANAGER_ML_PACKAGE_VERSION_CLONE_REQUEST_NOT_ACCEPTABLE',
    20112: 'PKGMANAGER_TENANT_NOT_AUTHORIZED',
    20113: 'PKGMANAGER_PUBLIC_TENANT_CANNOT_BE_DELETED',
    20201: 'PKGMANAGER_ERROR_CREATING_PROJECT',
    20202: 'PKGMANAGER_ERROR_UPDATING_PROJECT',
    20203: 'PKGMANAGER_ERROR_DELETING_PROJECT',
    20204: 'PKGMANAGER_ERROR_FINDING_PROJECT_BY_ID',
    20205: 'PKGMANAGER_ERROR_SEARCHING_PROJECT',
    20208: 'PKGMANAGER_ERROR_DELETING_PROJECT_ONE_OR_MORE_PACKAGES_IN_NON_DELETABLE_STATE',
    20209: 'PKGMANAGER_ERROR_DELETING_PROJECT_ALREADY_IN_DELETING_STATE',
    20210: 'PKGMANAGER_ERROR_FETCHING_PROJECT_IDS',
    20212: 'PKGMANAGER_ERROR_DELETING_PROJECT_HAS_ACTIVE_PIPELINES',
    20301: 'PKGMANAGER_ML_PACKAGE_VERSION_CLONE_REQUEST_NOT_ACCEPTABLE_UNAUTHORIZED_TENANT',
    20309: 'PKGMANAGER_ML_PACKAGE_VERSION_OVERWRITE_ERROR',
    40201: 'DEPLOYER_TAGGING_FAILED',
    40202: 'DEPLOYER_BUILD_FAILED',
    40203: 'DEPLOYER_ERROR_CREATING_IMAGE',
    40204: 'DEPLOYER_ERROR_PUSHING_IMAGE',
    40206: 'DEPLOYER_ERROR_WITH_CONNECTION',
    40301: 'DEPLOYER_KUBE_OP_FAILED',
    40506: 'DEPLOYER_ERROR_PINGING_DOCKER_DAEMON',
    40907: 'DEPLOYER_ERROR_DEPLOYMENT_NOT_FOUND',
    40908: 'DEPLOYER_INVALID_ML_PACKAGE_LANGUAGE_MSG',
    40909: 'DEPLOYER_INVALID_ML_PACKAGE_LANGUAGE',
    40603: 'DEPLOYER_CONTENT_URI_NOT_FOUND',
    40604: 'DEPLOYER_INVALID_PACKAGE_VERSION_STATE',
    40605: 'DEPLOYER_INVALID_ACTION_FOR_NON_PUBLIC_TENANT',
    40606: 'DEPLOYER_IMAGE_URI_NOT_FOUND',
    41001: 'DEPLOYER_ERROR_UNSUPPORTED_CREDENTIAL_TYPE',
    41002: 'DEPLOYER_JOB_NOT_FOUND',
    40901: 'DEPLOYER_ERROR_LISTING_SERVICE_ENTRIES',
    40902: 'DEPLOYER_ERROR_CREATING_SERVICE_ENTRY',
    40903: 'DEPLOYER_ERROR_DELETING_SERVICE_ENTRY',
    40904: 'DEPLOYER_SERVICE_ENTRY_ALREADY_EXISTS',
    40002: 'DEPLOYER_TENANT_PROVISION_REQUEST_CONFLICT',
    40003: 'DEPLOYER_ERROR_DUPLICATE_NAME',
    40004: 'DEPLOYER_ERROR_FINDING_PROVISIONED_TENANTS',
    40005: 'DEPLOYER_ERROR_MERGING_TENANTS',
    40007: 'DEPLOYER_TENANT_NOT_PROVISIONED',
    40008: 'DEPLOYER_ML_SKILL_NAME_INVALID',
    40010: 'DEPLOYER_TENANT_PROVISION_FAILED',
    40011: 'DEPLOYER_TENANT_DEPROVISION_REQUEST_CONFLICT',
    40012: 'DEPLOYER_QUOTA_EXCEEDS_LOG_MSG',
    40014: 'DEPLOYER_MLSKILL_DEPLOYMENT_NONRETRYABLE_ERROR',
    40015: 'DEPLOYER_SKILLS_MIGRATION_INVALID_REQUEST',
    40016: 'DEPLOYER_MLSKILL_UPDATE_RETRYABLE_ERROR',
    40017: 'DEPLOYER_TENANT_DEPROVISION_RETRYABLE_ERROR',
    40018: 'DEPLOYER_TENANT_ALREADY_DELETED',
    40019: 'DEPLOYER_ML_SKILL_NAME_LENGTH_INVALID',
    40020: 'DEPLOYER_TENANT_ALREADY_DELETING',
    40021: 'DEPLOYER_TRAINING_NEEDED_BEFORE_DEPLOYMENT',
    40401: 'DEPLOYER_SM_INVALID_TRANSITION',
    40402: 'DEPLOYER_SM_BLOCKED_BY_GUARD',
    40403: 'DEPLOYER_SM_INCONSISTENT_STATE',
    40507: 'DEPLOYER_ISTIO_CREATE_PROXY_FAILED',
    40508: 'DEPLOYER_ISTIO_REMOVE_PROXY_FAILED',
    40601: 'DEPLOYER_ML_PACKAGE_IMAGE_NOT_FOUND',
    40602: 'DEPLOYER_INVALID_DOCKER_REPOSITORY_PATH',
    40701: 'DEPLOYER_ERROR_DELETING_ML_SKILL',
    40801: 'DEPLOYER_LICENSE_RESOURCE_MAPPING_NOT_FOUND',
    40802: 'DEPLOYER_INSUFFICIENT_LICENSE_RESOURCES',
    60209: 'TRAINER_INSUFFICIENT_LICENSE_RESOURCES',
    40803: 'DEPLOYER_MAX_RESOURCE_LICENSE_ALLOWED_FOR_ML_SKILL_EXCEEDED',
    40806: 'DEPLOYER_MAX_RESOURCE_DEPLOYMENT_ALLOWED_FOR_ML_SKILL_EXCEEDED',
    40905: 'DEPLOYER_ERROR_SENDING_LICENSE_USAGE_STATS',
    40906: 'DEPLOYER_ERROR_RETRIEVING_DEPLOYMENT_LOGS',
    42001: 'DEPLOYER_CV_PROXY_INVALID_ENVIRONMENT_VARIABLES',
    42002: 'DEPLOYER_INVALID_DEPENDENCY_GRAPH',
    60001: 'TRAINER_ACTIVE_JOB_STATUS',
    60002: 'TRAINER_JOB_TIMEOUT',
    60003: 'TRAINER_INVALID_RUN_STATUS_FOR_ACTION',
    60004: 'MLSKILL_ERROR_UPDATING_MLSKILL',
    60016: 'ML_SKILL_ERROR_TRAIN_ML_PACKAGE_VERSION_BEFORE_USE',
    60101: 'TRAINER_ERROR_CREATING_DATASET',
    60102: 'TRAINER_ERROR_UPDATING_DATASET',
    60103: 'TRAINER_ERROR_DELETING_DATASET',
    60104: 'TRAINER_ERROR_FINDING_DATASET_BY_ID',
    60105: 'TRAINER_ERROR_SEARCHING_DATASET',
    60107: 'TRAINER_DATASET_NAME_INVALID',
    60108: 'TRAINER_ERROR_FETCHING_DATASETS_BY_PROJECT_ID',
    60109: 'TRAINER_ERROR_FETCHING_LOGS_BY_RUN_ID',
    60208: 'TRAINER_LICENSE_RESOURCE_MAPPING_NOT_FOUND',
    60301: 'TRAINER_TENANT_PROVISIONING_INPROGRESS',
    60302: 'TRAINER_ERROR_FINDING_PROVISIONED_TENANTS',
    60303: 'TRAINER_TENANT_PROVISION_FAILED',
    60304: 'TRAINER_SEND_MESSAGE_TOGGLE_SCHEDULED_PIPELINE_SUSPENSION_FAILED',
    60305: 'TRAINER_ERROR_RUN_ALREADY_IN_KILLED_STATE',
    60307: 'TRAINER_TENANT_NOT_PROVISIONED',
    60308: 'TRAINER_PIPELINE_TRIGGER_TIME_LAGS_CURRENT_TIME',
    60309: 'TRAINER_ERROR_REFRESHING_EXPIRING_SAS_URL_FOR_ACTIVE_TENANTS',
    60401: 'TRAINER_SM_INCONSISTENT_STATE',
    60710: 'TRAINER_INVALID_ENV_VARS',
    70101: 'APPMANAGER_APP_ALREADY_PRESENT',
    70102: 'APPMANAGER_APP_NAME_INVALID',
    70103: 'APPMANAGER_APP_SEARCHING_ERROR',
    70104: 'APPMANAGER_APP_NAMESPACE_CREATION_ERROR',
    70105: 'APPMANAGER_APP_SECRET_CREATION_ERROR',
    70106: 'APPMANAGER_APP_VIRTUAL_SERVICE_CREATION_ERROR',
    70107: 'APPMANAGER_DEPLOYMENT_CREATION_ERROR',
    70108: 'APPMANAGER_SERVICE_CREATION_ERROR',
    70109: 'APPMANAGER_APP_DEPLOYMENT_VALIDATION_ERROR',
    70110: 'APPMANAGER_PVC_ATTACHMENT_ERROR',
    70111: 'APPMANAGER_PVC_INIT_CONTAINER_MOUNT_ERROR',
    70112: 'APPMANAGER_PVC_CONTAINER_MOUNT_ERROR',
    70113: 'APPMANAGER_APP_HELPER_SIDECAR_ATTACHMENT_ERROR',
    70114: 'APPMANAGER_SECRET_ENCODING_ERROR',
    70115: 'APPMANAGER_INVALID_APP_DELETION_ERROR',
    70116: 'APPMANAGER_APP_NOT_PRESENT',
    70117: 'APPMANAGER_APP_DELETION_ERROR',
    70118: 'APPMANAGER_ERROR_GETTING_REGISTRY_URL',
    70119: 'APPMANAGER_ERROR_GETTING_CONTAINER_REPOSITORY',
    70120: 'APPMANAGER_ERROR_KEY_NOT_PRESENT',
    70121: 'APPMANAGER_INVALID_ARGUMENT',
    70122: 'APPMANAGER_ACCESS_DENIED',
    70128: 'APPMANAGER_DATASET_ALREADY_IN_USE',
    70129: 'APPMANAGER_DATASET_DELETION_ERROR',
    71004: 'APPMANAGER_SEND_TO_LABELLERS_ERROR',
    71006: 'APPMANAGER_SEND_TO_LABELLERS_FOLDER_ACCESS_ERROR',
    72003: 'APPMANAGER_CANNOT_DELETE_FILE_IN_ACTION_CENTER',
    72004: 'APPMANAGER_INVALID_LABELING_TEMPLATE',
    72005: 'FAILED_VALIDATION_FILES_FOUND',
    103: 'PKGMANAGER_ML_PACKAGE_LANGUAGE_NOT_FOUND',
    104: 'PKGMANAGER_ML_PACKAGE_INPUT_TYPE_NOT_FOUND',
    105: 'PKGMANAGER_ML_PACKAGE_NAME_NOT_FOUND',
    106: 'PKGMANAGER_ML_PACKAGE_IMPORT_PACKAGE_AND_METADATA_MISMATCH',
    107: 'PKGMANAGER_ML_PACKAGE_IMPORT_PUBLIC_PACKAGE_AND_METADATA_MISMATCH',
    108: 'PKGMANAGER_ML_PACKAGE_IMPORT_PUBLIC_PACKAGE_MISSING_INFO',
    109: 'PKGMANAGER_ML_PACKAGE_OOB_MISSING_MODEL',
    DEFAULT: 'COMMON_UNHANDLED_EXCEPTION',
};

export default (code: number | string, other?: string) => ExceptionMap[code] || other || 'COMMON_UNHANDLED_EXCEPTION';
