import { styled } from '@mui/material';
import Tokens from '@uipath/apollo-core';
import React, {
    useCallback,
    useEffect,
    useRef,
} from 'react';
import {
    useHistory,
    useRouteMatch,
} from 'react-router-dom';

import { bindKeyTo } from '../utils/a11y';

interface SideBarLinkProps {
    name: string;
    path: string;
    isFirstElement: boolean;
}

const Root = styled('div')<{ isActive?: boolean }>(({
    theme, isActive,
}) => ({
    display: 'flex',
    flexDirection: 'row',
    height: '48px',
    width: '310px',

    borderBottom: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: theme.palette.semantic.colorBackgroundGray,

    borderLeft: 'solid',
    borderLeftWidth: '1px',
    borderLeftColor: theme.palette.semantic.colorBackgroundGray,
    cursor: 'pointer',

    '&:hover': {
        backgroundColor: 'rgba(250, 70, 22, 0.05)',

        borderLeft: 'solid',
        borderLeftWidth: '1px',
        borderLeftColor: theme.palette.semantic.colorBackgroundGray,
    },

    '&:focus': {
        backgroundColor: 'rgba(250, 70, 22, 0.05)',

        borderLeft: 'solid',
        borderLeftWidth: '1px',
        borderLeftColor: theme.palette.semantic.colorBackgroundGray,
    },

    ...(isActive && {
        backgroundColor: 'rgba(250, 70, 22, 0.05)',
        boxShadow: `${Tokens.Colors.ColorOrange500} 4px 0px 0px inset`,
        '&:hover': { backgroundColor: 'rgba(250, 70, 22, 0.05)' },
    }),

    '& .content': {
        flexGrow: 1,
        color: theme.palette.semantic.colorIconDefault,
        fontWeight: 600,
        fontSize: Tokens.FontFamily.FontMSize,
        paddingLeft: '20px',
        paddingTop: '14px',
        textDecoration: 'none',
    },
}));

const SideBarLink: React.FC<SideBarLinkProps> = props => {
    const match = useRouteMatch({
        path: props.path,
        exact: false,
    });
    const linkRef = useRef<HTMLDivElement | null>(null);
    const cb = useCallback(bindKeyTo({
        ref: linkRef,
        cycle: true,
    }), [ linkRef ]);
    const history = useHistory();

    const handleOnClick = (): void => {
        history.push(props.path);
    };

    useEffect(() => {
        if (match && linkRef.current && document.activeElement === document.querySelector('body')) {
            linkRef.current.focus();
        }
    }, [ match, linkRef.current ]);

    return (
        <Root
            onClick={handleOnClick}
            data-testid="side-bar-link"
            isActive={!!match}
            tabIndex={props.isFirstElement ? 0 : -1}
            ref={linkRef}
            onKeyDown={cb}
            role="navigation"
            aria-label={props.name}
        >
            <div
                className="content"
                data-cy={props.name}
                aria-hidden>
                {props.name}
            </div>
        </Root>
    );
};

export default SideBarLink;
