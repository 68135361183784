import { styled } from '@mui/material';
import type { ProjectDto } from '@uipath/aifabric';
import Tokens from '@uipath/apollo-core';
import moment from 'moment';
import React, {
    useContext,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    generatePath,
    useHistory,
    useParams,
} from 'react-router-dom';

import { deletePipelineRun } from '../../../api/client/pipelineManagerClient';
import { useFeedback } from '../../../api/global/useFeedback';
import { CustomDialog } from '../../../components/Dialog';
import Section from '../../../components/Section';
import type { ResponsiveColumnDefinition } from '../../../components/Table/BaseTable/types';
import { getFilterRadioFromOptions } from '../../../components/Table/FilterUtils';
import ServerSideTable from '../../../components/Table/ServerSideTable/ServerSideTable';
import URLManager from '../../../config/URLManager';
import { AppPermissions } from '../../../enums/Authorization';
import { PermissionsContext } from '../../../providers/PermissionsProvider';
import { ProjectsContext } from '../../../providers/ProjectsProvider';
import { RoutePath } from '../../../route/routeMap';
import {
    calculateDuration,
    checkIfValueExists,
    dataFormatter,
    extractErrorMessage,
} from '../../../utils/CommonUtils';
import { dateFormatter } from '../../../utils/DateFormatter';
import logger from '../../../utils/Logging';

const PipelineListPageContainer = styled('div')({
    width: '100%',
    fontSize: Tokens.FontFamily.FontMSize,
    marginRight: '50px',
    fontFamily: Tokens.FontFamily.FontNormal,
    overflowY: 'auto',
});

const PipelineListPageContent: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { projectName } = useParams<{ projectName: string }>();
    const {
        currentProject, currentProjectId,
    } = useContext(ProjectsContext).state;
    const { state: permissionsState } = useContext(PermissionsContext);
    const permissions = permissionsState.projectData[currentProjectId ?? '']?.permissionSet || [];

    const buttonItems = [];

    if ((permissions.indexOf(AppPermissions.MLPipeline_Create) > -1)) {
        buttonItems.push({
            buttonText: 'pipeline_list_create_new_button',
            buttonCallback: () => {
                history.push(generatePath(RoutePath.CREATE_PIPELINE, { projectName }));
            },
        });
    }

    return (
        <PipelineListPageContainer
            data-testid="pipeline-list-page">
            <Section
                title={t('pipeline_list_title')}
                buttonItems={buttonItems}>
                {(permissions.indexOf(AppPermissions.MLPipeline_View) > -1) && currentProject ? (
                    <PipelineList
                        currentProject={currentProject}
                        canDelete={(permissions.indexOf(AppPermissions.MLPipeline_Delete) > -1)} />
                ) : null}
            </Section>
        </PipelineListPageContainer >
    );
};

interface PipelineListProps {
    currentProject: ProjectDto | undefined;
    canDelete: boolean;
    sourcePackageId?: string;
}

interface PipelineSourceVersionProps {
    sourceVersion?: string;
    sourceCustomVersion?: string;
}

export const PipelineList: React.FC<PipelineListProps> = ({
    currentProject, sourcePackageId, canDelete,
}) => {

    const {
        t, i18n,
    } = useTranslation();
    const dataMapper: ResponsiveColumnDefinition[] = [
        {
            id: 'pipeline.displayName',
            header: `${t('pipeline_displayName_label')}`,
            accessorKey: 'pipeline.displayName',
            enableSorting: true,
        },
        {
            header: `${t('common_package_name_label')}`,
            accessorKey: 'sourceName',
            enableSorting: true,
        },
        {
            id: 'pipeline.pipelineType',
            header: `${t('common_type_label')}`,
            accessorKey: 'pipeline.pipelineType',
            enableSorting: true,
            cell: ({ cell: { getValue } }): string => t(`PIPELINE_${getValue()}`),
            meta: {
                filter: getFilterRadioFromOptions([
                    {
                        key: 'EVALUATE_ONLY',
                        value: t('PIPELINE_EVALUATE_ONLY'),
                    },
                    {
                        key: 'FULL_TRAINING',
                        value: t('PIPELINE_FULL_TRAINING'),
                    },
                    {
                        key: 'TRAIN_ONLY',
                        value: t('PIPELINE_TRAIN_ONLY'),
                    },
                ]),
                apiField: 'pipelineType',
            },
            mdDown: true,
        },
        {
            header: `${t('common_version_label')}`,
            accessorKey: 'sourceVersion',
            enableSorting: true,
            cell: ({ row }): string | undefined => {
                const pipelineSourceVersion: PipelineSourceVersionProps = row.original;
                return pipelineSourceVersion.sourceCustomVersion ? pipelineSourceVersion.sourceCustomVersion : pipelineSourceVersion.sourceVersion;
            },
            smDown: true,
        },
        {
            header: `${t('common_status_label')}`,
            accessorKey: 'displayStatus',
            cell: ({ cell: { getValue } }): string => dataFormatter(t(`RUNS_${getValue()}`), i18n.language),
            meta: {
                filter: getFilterRadioFromOptions([
                    {
                        key: 'SCHEDULED',
                        value: t('RUNS_SCHEDULED'),
                    },
                    {
                        key: 'PACKAGING',
                        value: t('RUNS_PACKAGING'),
                    },
                    {
                        key: 'WAITING_FOR_RESOURCES',
                        value: t('RUNS_WAITING_FOR_RESOURCES'),
                    },
                    {
                        key: 'QUEUED_FOR_RESOURCES',
                        value: t('RUNS_QUEUED_FOR_RESOURCES'),
                    },
                    {
                        key: 'RUNNING',
                        value: t('RUNS_RUNNING'),
                    },
                    {
                        key: 'SUCCESSFUL',
                        value: t('RUNS_SUCCESSFUL'),
                    },
                    {
                        key: 'FAILED',
                        value: t('RUNS_FAILED'),
                    },
                    {
                        key: 'KILLED',
                        value: t('RUNS_KILLED'),
                    },
                    {
                        key: 'TRAINED_NOT_EVALUATED',
                        value: t('RUNS_TRAINED_NOT_EVALUATED'),
                    },
                ]),
                apiField: 'status',
            },
        },
        {
            header: `${t('common_created_label')}`,
            accessorKey: 'createdOn',
            enableSorting: true,
            cell: ({ cell: { getValue } }): string => dateFormatter(getValue(), i18n.language),
            meta: {
                filter: getFilterRadioFromOptions([
                    {
                        key: moment().subtract(1, 'days')
                            .format('DD-MM-YYYY'),
                        value: t('label_last_day'),
                    },
                    {
                        key: moment().subtract(7, 'days')
                            .format('DD-MM-YYYY'),
                        value: t('label_last_week'),
                    },
                    {
                        key: moment().subtract(30, 'days')
                            .format('DD-MM-YYYY'),
                        value: t('label_last_30_days'),
                    },
                ]),
                apiField: 'fromDate',
            },
        },
        {
            header: `${t('pipeline_list_duration_header')}`,
            accessorKey: '',
            cell: ({ row }): string => calculateDuration(row.original),
            smDown: true,
        },
        {
            header: `${t('pipeline_list_score_header')}`,
            accessorKey: 'score',
            cell: ({ cell: { getValue } }): string => checkIfValueExists(getValue()),
            mdDown: true,
        },
    ];

    const history = useHistory();
    const feedback = useFeedback();
    const [ removeDialogOpen, setRemoveDialogOpen ] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ activePipeline, setActivePipeline ] = useState<any>(undefined);
    const [ itemDeletedFlag, setItemDeletedFlag ] = useState(false);

    const url: string = React.useMemo(() => {
        let runsUrl = URLManager.url().apiTrainer + '/runs?sortBy=createdOn&sortOrder=DESC&projectId=' + currentProject?.id;

        if (sourcePackageId) {
            runsUrl = runsUrl + '&sourcePackageId=' + sourcePackageId;
        }
        return runsUrl;
    }, [ currentProject ]);

    const closeDialogEvent = (): void => {
        setRemoveDialogOpen(false);
    };

    const removePipeline = (): void => {
        if (!canDelete) {
            return;
        }
        deletePipelineRun(activePipeline.original.id, currentProject?.id).then(response => {
            closeDialogEvent();
            if (response.respCode === 200) {
                feedback.enqueueSuccess(t('feedback_delete_success_pipeline'));
            } else if (response.respCode === 202) {
                feedback.enqueueSuccess(t('pipeline_list_feedback_accepted'));
            }

            // toggle the flag to reload table data
            setItemDeletedFlag(prev => !prev);

            return true;
        })
            .catch(error => {
                closeDialogEvent();
                logger.error({
                    identifier: 'Pipeline list',
                    message: 'Error deleting pipeline run',
                    error,
                });
                feedback.enqueueError(extractErrorMessage(
                    error,
                    t('feedback_delete_error_pipeline'),
                    {
                        10602: {
                            1: 'Pipeline',
                            0: activePipeline.original.id,
                        },
                    },
                ));
            });
    };

    const menuItems = [];
    if (canDelete) {
        menuItems.push({
            text: 'pipeline_list_context_menu_remove',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (event: any, row: any): void => {
                setActivePipeline(row);
                setRemoveDialogOpen(true);
            },
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handlePipelineClickEvent = (rowInfo: any): void => {
        history.push(
            {
                pathname: generatePath(RoutePath.PIPELINE_DATA, {
                    projectName: currentProject?.name,
                    pipelineRunId: rowInfo.original.id,
                }),
                state: { status: rowInfo.original.displayStatus },
            },
        );
    };

    return (
        <>
            <CustomDialog
                title={t('pipeline_remove_dialog_title_text')}
                open={removeDialogOpen}
                handleClose={closeDialogEvent}
                closeIconButton
                infoText={t('pipeline_remove_dialog_info_text')}
                warningText={t('pipeline_remove_dialog_warning_text')}
                confirmationText={t('pipeline_remove_dialog_confirmation_text')}
                primaryButtonText={t('dialog_button_confirm_text')}
                secondaryButtonText={t('dialog_button_cancel_text')}
                primarybuttonCallback={removePipeline}
                secondarybuttonCallback={closeDialogEvent}
            />
            <ServerSideTable
                url={url}
                totalKey="data.totalCount"
                dataKey="data.dataList"
                mapper={dataMapper}
                hasFilters
                alwaysFilterVisible
                contextMenuItems={menuItems}
                itemDeletedFlag={itemDeletedFlag}
                onTableCellClicked={handlePipelineClickEvent}
                keyColumnIndex={0}
                level="pipelines_pagesize"
            />
        </>
    );
};

export default connect(() => ({}))(PipelineListPageContent);
